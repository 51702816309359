<template>
  <div>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <section class="section container">
      <p>Congrats, you just earned ${{ awardValue }}.</p>
      <p><a @click="go('/payouts')">Click here to view balance and payouts</a></p>
      <div class="buttons" style="margin-top:20px;">
        <button 
          @click="go('/')"
          class="button is-medium is-fullwidth is-info">Browse deals</button> 
      </div>
    </section>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;
    },
    awardValue: function() {
      return Math.round(this.currentDeal.award_value / 100)
    }
  },
  mounted() {
    this.updateData()
  },
  methods: {
    updateData() {
      if (!this.currentDeal || this.currentDeal.slug !== this.slug) {
        this.$store.dispatch("getDeal", this.slug)
          .then(() => {
            if (!this.currentDeal) {
              this.error = "Deal not found!"
            }
          })
          .catch(err => {
            if (err instanceof AuthError || err instanceof NetworkError) {
              throw err
            } else {
              console.log(err)
              this.error = err
            }
          })
      }
    },
    go(path) {
      this.$router.push(path)
    }
  }
}
</script>
